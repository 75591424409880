import SystemConfigVue from '@/views/SystemConfig.vue'
import http from '../utils/request'

export default {
    
    //用户登录
     login(data){
        return http.post('/api/user/login',data)
    },

    //登出
    logout(){
        return http.get('/api/user/logout')
    },

    //用户统计
    userStatistics(){
        return http.get('/api/user/use/statistics')
    },
    //用户列表
    userList(data){
        return http.get('/api/user/list/page',data)
    },
    

    //对象列表
    objList(data){
        return http.get('/api/uniapp/obj/list',data)
    },
    //对象添加
    objAdd(data){
        return http.post('/api/uniapp/obj/add',data)
    },
    //对象修改
    objUpdate(data){
        return http.post('/api/uniapp/obj/update',data)
    },    
    //对象删除
    objDel(data){
        return http.get('/api/uniapp/obj/del',data)
    },
    //系统配置设置
     setSystemConfig(data){
        return http.post('/api/system/set',data)
     },
     //系统配置查询
     getSystemConfig(){
        return http.get('/api/system/get')
     }
   

}

<template>
    <el-descriptions title="自定义样式列表" :column="3" border :contentStyle="CS" :label-style="LS" >
        <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
        <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
        <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
        <el-descriptions-item label="备注">
                <el-tag size="small">学校</el-tag>
         </el-descriptions-item>
        <el-descriptions-item label="联系地址" span="2" :contentStyle="{'text-align': 'right'}">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
        <el-descriptions-item label="居住地2" span="3"  >苏州市2</el-descriptions-item>
        <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
        <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
  </el-descriptions>
</template>
<script>
      export default{
        data(){
            return{
                CS: {
                    'text-align': 'center',  //文本居中
                    'min-width': '250px',   //最小宽度
                    'word-break': 'break-all'  //过长时自动换行
                    },
                LS: {
                    'color': '#000',
                    'text-align': 'center',
                    'font-weight': '600',
                    'height': '40px',
                    'background-color':'#FFBBFF',
                    'min-width': '110px',
                    'max-width': '110px',
                    'word-break': 'break-all'
                }
            }
        }
      }

</script>

<style lang="less" scoped>
    //https://blog.csdn.net/zujiasheng/article/details/134203815
    //https://blog.csdn.net/emoji111111/article/details/134078887
    :deep(.el-descriptions__body) {
    .el-descriptions__content.el-descriptions__cell.is-bordered-content {
        min-width: 70px;
        max-width: 70px;
        word-break: break-all; // 让内容超出列宽时自动换行显示
        word-wrap: break-word;
    }
    }
</style>
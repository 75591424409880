<template>
         <GoodList :goods="mallList"  v-loading="loading"/>
</template>
<script>
    import GoodList from '../components/GoodsList.vue'

    export default {
        data(){
            return {
                loading:true,
                mallList:[]
            }
        },
        components:{
            GoodList,
        },
        mounted(){
           this.getMallList();
           console.log("domain:"+this.$domain);
        } ,
        methods:{
                //异步操作
                getMallList(){
                let param ={
                        params:{
                            objType:"key-mall",
                            pageNum:1,
                            pageSize:9999
                    }                  
                }
                this.$httpapi.objList(param).then((data) =>{
                        console.log(data)
                            if(data.data.code === 200){                                
                                this.mallList = data.data.data.list;                                                                   
                            } else{
                                this.$message.error(data.data.msg)
                            }
                        })
                        this.$myUtils.wait(500).then(() => {
                               this.loading = false;
                           })
            },
        }
    }
</script>

<style lang="less" scoped>
</style>
<template>
   
       <div class="tabs">
           <el-tag
                v-for="(item,index) in tags"
                :key="item.path"
                :closable="item.name !=='home'"   
                :effect="$route.name === item.name ? 'dark':'plain'"
                :disable-transitions="false"
                @click="changeMenu(item)"
                @close="handleClose(item,index)"
                size="medium">
                {{item.label}}
            </el-tag>
       </div>
</template>
<script>
 import { mapState,mapMutations } from 'vuex';
 export default{
  //name: 'CommonTag',
   data() {
       return {}
   },
   computed:{
     ...mapState({
             // tags:state => state.tab.tabList
              tags: (state) => { 
                return state.tab.tabList // 映射 tags 为 store.tab.tabList
              },
           })
       
   },
   methods:{
           ...mapMutations(['closeTag']),
            changeMenu(item){
                //这四种都可以
                //this.$router.push('/home')
                this.$router.push(item.path)
                //this.$router.push({name:item.name})
                //this.$router.push({path:item.path})
            },
            //点击tag删除
            handleClose(item,index){
               this.closeTag(item);
               const length = this.tags.length;
               //删除之后的跳转逻辑
               if(item.name !== this.$route.name){
                  return;
               }
               //如果删除的是最后一项
               if(index === length)
               {
                    this.$router.push(this.tags[index -1].path)
               }else{
                  this.$router.push(this.tags[index].path)
               }
            }
   }
 }
</script>
<style lang="less" scoped>
      .tabs{
        padding: 10px;
      
        .el-tag{
           margin-right: 15px;
           cursor: pointer; 
        }
      }

</style>
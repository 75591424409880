<template>
     <div class="manage">       
        <div class="menage-header">
          <!--搜索区域--> 
          <el-form class="query" :inline="true" :model="userSeachForm">
             <el-form-item>
                     <el-input placeholder="请输入姓名" v-model="userSeachForm.name"></el-input>
             </el-form-item>
             <el-form-item>
                   <el-button type="primary" @click="querysubmit">查询</el-button>
             </el-form-item>
          </el-form>
        </div>

        <div class="common-table">
          <el-table
                 v-loading="loading"
                  stripe
                   height="90%"
                  :data="tableData"
                  border
                  style="width: 100%;">
                  <el-table-column
                    prop="userName"
                    label="用户名"
                   >
                  </el-table-column>
                  <el-table-column
                    prop="email"
                    label="邮件"
                   >
                  </el-table-column>         
                  <!-- 
                    shape 图片显示方式 circle为原型像 square为方形
                    size 图片的大小
                    src 图片的路径
                    -->
                  <el-table-column prop="avatar" label="头像" width="90"  align="center">
                    <template slot-scope="scope">
                        <el-avatar  shape="circle" :size="50" :src="scope.row.avatar"  @click.native="showImage(scope.row.avatar)"></el-avatar>
            　　    </template>
                  </el-table-column>
                  
                  <el-table-column
                    prop="userGroup"
                    label="用户组">
                   <!--自定义列 1、0转男、女-->
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{ scope.row.userGroup==1?'管理员':'普通用户' }}</span>
                    </template>

                  </el-table-column>
                  <el-table-column
                    prop="createTime"
                    label="创建时间">
                  </el-table-column>
                  <el-table-column
                    prop="remark"
                    label="描述">
                  </el-table-column>
          </el-table>
           <div class="pager">
             <el-pagination
                layout="prev, pager, next"
                :total="tableTotal"
                @current-change="handle_Page"> <!--事件用@ 属性用冒号：-->
              </el-pagination>
           </div> 
        </div>  
   
        <el-dialog :visible.sync="dialogVisible" width="34%">
            <img :src="currentImage" style="width: 100%" />
       </el-dialog>
      </div>
</template>
<script>

    export default{
         data(){
            return {
              loading: true,
              dialogVisible:false,
               currentImage: '',
                userStatistics:{
                    userAll:'',
                    userRegeditToday:'',
                    usedOneWeek:'',
                    usedOneMonth:'',
                    usedThreeMonth:''
                },
                tableData:[],
                userSeachForm:{
                    name:''
                },
                tableTotal:0, //当前的总条数
                pageData:{
                    pageNum:1,
                    pageSize:10
                }, 
            }
        },
        methods:{
          showImage(imageUrl){
            this.currentImage = imageUrl;
             this.dialogVisible = true;
         },
          //同步操作
          async getUserStatistics()
            {
              console.log("1111111111111")
              await  this.$httpapi.userStatistics().then((data) =>{
                           console.log("222222222")
                        console.log(data)
                            if(data.data.code === 200){                                
                              this.userStatistics = data.data.data;                                                     
                            } else{
                                this.$message.error(data.data.msg)
                            }
                       })
               console.log("333333333")       
            },
           //异步操作
            getUserList()
            {
                let param ={
                        params:{
                            pageNum:this.pageData.pageNum,
                            pageSize:this.pageData.pageSize,
                            name:this.userSeachForm.name
                    }                  
                }
                this.$httpapi.userList(param).then((data) =>{
                        console.log(data)
                            if(data.data.code === 200){                                
                              this.tableData = data.data.data.list; 
                              this.tableTotal = data.data.data.total;                                                    
                            } else{
                                this.$message.error(data.data.msg)
                            }
                            this.$myUtils.wait(500).then(() => {
                                this.loading = false;  
                            })
                       })
            },

             //列表查询
            querysubmit(){
                this.getUserList()
            },
            //选择页码的回调函数
            handle_Page(val){
                this.pageData.pageNum = val
                this.getUserList();
            },
        },
        mounted(){
              this.getUserStatistics();         
              this.getUserList();
        }
    }
</script>
<style lang="less" scoped>
 .manage{
         height: 100%;
         .menage-header{
            height: 40px;
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .query{
               margin-top: 0px;
            }
         }
         .common-table
         {
             position: relative;
             height: calc(100% - 40px);
             .pager{
              position: absolute;
              bottom: 0;
              right: 20px;
             }
             :deep( .el-table .cell) {      
                  height: 50px;  
                  line-height: 50px;
              }
         }
         :deep(.query  .el-form-item ){//搜索栏
              margin-bottom: 0px
          }
     }
</style>
import axios from 'axios'
import router from '@/router'

const http = axios.create({
    //通用请求地址前缀
    baseURL:'https://wandao666.top/',
    timeout:10000, //超时时间
    emulateJSON: true,
    //headers:{
    //    "token": window.localStorage.getItem('token'),
    //    "serialNo": 'Mng1-' +  new Date().getTime()
    //  }
})
// 设置全局的 Content-Type
http.defaults.headers.common['Content-Type'] = 'application/json';

//在这个里面的参数会每次都更新，放在外面是写死不变的
http.interceptors.request.use(function(config){
    //在发送请求前做些什么 此处不设置token 会带上一次的token
    config.headers.token = window.localStorage.getItem('token')
    config.headers.serialNo = 'Mng-' +  new Date().getTime()
    return config;
},function(error){
    //对请求错误做些什么
    return Promise.reject(error);
});

//添加响应拦截器
http.interceptors.response.use(function (response){
    //token过期时统一处理，返回登录页
    switch (response.data.code) {
        case 401:
          localStorage.removeItem('token');
          router.push('/'); //这里不能用this.$router，因为this进不来
      }

    //对响应数据做点什么
    return response;
},function(error){
    return Promise.reject(error);
});

export default http
<template>
      <div class="header-content">
         <el-dropdown @command="handleClick">
            <span class="el-dropdown-link">
               <img class="user" src="../assets/image/header.jpg">
            </span>
            <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="personCenter">个人中心</el-dropdown-item>
                  <el-dropdown-item command="logout">退出</el-dropdown-item>   
            </el-dropdown-menu>
        </el-dropdown>        
      </div>
</template>
<script>
     export default{
      data() {
       return {}
      },
        methods:{
         handleClick(e){
            if(e === 'logout')
            {
               console.log("退出登录")
               this.$httpapi.logout().then((data) =>{
                        console.log(data)
                        if(data.data.code === 200){                                
                           window.localStorage.removeItem('token');
                           window.localStorage.removeItem('userName');
                           
                              //跳转到主页面
                           this.$router.push('/')                            
                        } else{
                           this.$message.error(data.data.msg)
                        }
                })

             
            }else if(e == 'personCenter')
            {
               console.log("进入个人中心");
            }
         }
        }
     }
</script>
<style lang="less">
     .header-content{
         background-color: #333;
         display: flex;
         justify-content: right;
         align-items: center;
        // width: 100%;
         height: 60px;
         padding: 0 20px;
         .user{
            width: 40px;
            height: 40px;
            border-radius: 50%;
         }
     }
</style>
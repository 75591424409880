<template>
    <div>
        <div class="back">
            <el-tooltip class="item" effect="light" content="返回" placement="right">
                <el-button  circle  @click="goback"  icon="el-icon-back"></el-button>
            </el-tooltip>
        </div>
            
        <el-descriptions  title="" :column="2" border :contentStyle="CS" :label-style="LS" >
            <el-descriptions-item label="商品名称">{{ mallInfo.name }}</el-descriptions-item>
            <el-descriptions-item label="类型">{{ getTypeStr(mallInfo.type) }}</el-descriptions-item>
            <el-descriptions-item label="单位">{{mallInfo.unit}}</el-descriptions-item>
            <el-descriptions-item label="原来价格(元)">{{ mallInfo.priceOld }}</el-descriptions-item>
            <el-descriptions-item label="优惠价格(元)" >{{ mallInfo.priceNew }}</el-descriptions-item>
            <el-descriptions-item label="生产日期">{{ mallInfo.productionDate | formatDate }}</el-descriptions-item>
            <el-descriptions-item label="活动类型">{{ getEventTypeStr(mallInfo.eventType) }}</el-descriptions-item>
            <el-descriptions-item label="颜色">{{ getColorStr(mallInfo.color) }}</el-descriptions-item>
            <el-descriptions-item label="京东配送">{{  mallInfo.natureJD ==1?'是':'否' }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{  mallInfo.createDate }}</el-descriptions-item>
            <el-descriptions-item label="主图片"  span="2" :contentStyle="{'text-align': 'left'}">
                <img class="image" :src="this.$domain + mallInfo.imageMain" @click="showImage(mallInfo.imageMain)" />
            </el-descriptions-item>
            <el-descriptions-item label="描述" span="2" :contentStyle="{'text-align': 'left'}">{{  mallInfo.remark }}</el-descriptions-item>
        </el-descriptions>
    </div>
      
</template>

<script>
    export default{
        data(){
            return{
                domain: this.$domain,
                mallInfo:{},
                CS: {
                   // 'text-align': 'center',  //文本居中
                    'min-width': '250px',   //最小宽度
                    'word-break': 'break-all'  //过长时自动换行
                    },
                LS: {
                    'color': '#000',
                   // 'text-align': 'center',
                    'font-weight': '600',
                    'height': '40px',
                   // 'background-color':'#EDEDED',
                    'min-width': '110px',
                    'max-width': '110px',
                    'word-break': 'break-all'
                }
            }
        },
        methods:{
            goback(){
                this.$router.back()
            },
            getColorStr(key)
            {
                var myMap = new Map();
                myMap.set("1","红色");
                myMap.set("2","绿色");
                myMap.set("3","白色");
                myMap.set("4","黑色");
                return myMap.get(key);
            },
            getTypeStr(key)
            {
                var myMap = new Map();
                myMap.set("1","水果蔬菜");
                myMap.set("2","肉禽海产");
                myMap.set("3","粮油调味");
                myMap.set("4","休闲零食");
                myMap.set("5","电子产品");
                return myMap.get(key);
            },
            getEventTypeStr(key)
            {
                let str ="";
                var myMap = new Map();
                myMap.set("1","线上活动");
                myMap.set("2","地推活动");
                myMap.set("3","主题活动");
                myMap.set("4","品牌曝光");
                for (let i = 0; i < key.length; i++) {
                    if(i==0)
                    {
                        str =  myMap.get(key[i]);
                    }else{
                        str = str + "," + myMap.get(key[i])
                    }
                }
                    return str;
                }
        },
        mounted(){
            let str = window.sessionStorage.mallInfo;
            console.log(JSON.parse(str));
            //重新转换为对象
            this.mallInfo = JSON.parse(str);  
        }
    }
</script>

<style lang="less" scoped>
        .back{
            width: 100%;
            margin-bottom: 10px;
            text-align: left;
        }
        .image{//现在不用
                width: 200px;
                height: 200px;
                border-radius: 10%;
            }
</style>
export default{
    state:{
        count:0,
        name:'tom',
        age:20,
        sex:'男'
    },
    mutations:{ 
        //加值
        addCount(state,value){
            state.count = state.count + value; 
        },
        //减值
        minusCount(state,value){
            state.count = state.count - value; 
        }
    }
}
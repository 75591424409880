<template>
     <div class="content">
        <el-form ref="form" class="login-container" :rules="rules" :model="form" label-width="150px">
			<h1 class="title">系统设置</h1>
			<el-form-item label="API加密"  prop="sysApiEncrypt">
                <el-switch  :active-value="1" :inactive-value="0" v-model="form.sysApiEncrypt" active-color="#13ce66"  active-text="是" > </el-switch>
			</el-form-item>
			<el-form-item label="OpenAPI限流"  prop="sysOpenApiLimiterEncrypt" >
                <el-switch  :active-value="1" :inactive-value="0" v-model="form.sysOpenApiLimiterEncrypt"  active-color="#13ce66" active-text="是" > 
               </el-switch>
			</el-form-item>
            <el-form-item label="流量桶峰值"  prop="limitAll" >
                <el-input v-model.number="form.limitAll"></el-input>
			</el-form-item>
            <el-form-item label="每秒申请峰值"  prop="limitSpeed" >
                <el-input v-model.number="form.limitSpeed"></el-input>
			</el-form-item>
			<el-form-item>
                <el-button type="primary"  @click="doSubmit()">提交</el-button>
			</el-form-item>			
		</el-form>

  
     </div>
</template>
 
<script>
     export default{
        data(){
            return{
                form:{
                    sysApiEncrypt:0,
                    sysOpenApiLimiterEncrypt:0,
                    limitAll:10,
                    limitSpeed:3
                },
                rules:{
                    limitAll:[
                        {  required:true, message:'请输入流量桶峰值' },
                        { type:'number', message:'峰值必须为数字值', trigger:'blur' },
                        { validator: (rule, value, callback) => {
                            if (value < 10 || value > 100) {
                                callback(new Error('峰值必须在10到100之间'));
                            } else {
                                callback();
                            }
                            }, trigger: 'blur' } 
                    ],
                    limitSpeed:[
                        {  required:true, message:'请输入每秒申请峰值' },
                        { type:'number', message:'峰值必须为数字值', trigger:'blur' },
                        { validator: (rule, value, callback) => {
                            if (value < 1 || value > 100) {
                                callback(new Error('峰值必须在1到100之间'));
                            } else {
                                callback();
                            }
                            }, trigger: 'blur' } 
                    ],
               },
            }
        },
        mounted(){
            this.getSystemConfig();
        },
        methods:{
            doSubmit() {
             this.$refs.form.validate((valid) => {
                    if(valid)//验证通过 
                    {                              
                        this.$httpapi.setSystemConfig(this.form).then((data) =>{
                                console.log(data)
                                if(data.data.code === 200){                                                                   
                                    this.$message.info("系统设置成功.")                                           
                                } else{
                                    this.$message.error(data.data.msg)
                                }                           
                        })
                    }          
              })
            },
            getSystemConfig()
            {                       
                this.$httpapi.getSystemConfig().then((data) =>{
                        console.log(data)
                        if(data.data.code === 200){                                                                   
                           this.form = data.data.data;                                         
                        } else{
                            this.$message.error(data.data.msg)
                        }                           
                })        
            }
        }
     }
</script>
<style lang="less" scoped>
    .content{
        width: 50%;
        height: 100%;
        padding: 10px;
        :deep(.el-form-item__content) {
            text-align: left
        }
        :deep(.el-switch){
            margin-left: 20px;
        }
        :deep(.el-form-item__content){        
            width: 200px;
           
        }
        :deep(.el-input){
                margin-left: 20px;
        }
    }
</style>

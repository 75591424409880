<template>
    <div class="contentppp">
        <div class="left">
            <el-carousel class="imageP" indicator-position="none" >
                <el-carousel-item v-for="item in mallList" :key="item.id">              
               
                    <div class="divImage">
                        <img class ="image" :src="'https://wandao666.top/'+item.imageMain" alt />
                    </div>
                    
                    <span>{{item.name}}</span>
            
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="right">
              <div class="r-top">    
                <vue-seamless-scroll :data="newList" class="seamless" :class-option="defaultOption">
                    <ul class="item">
                    <li v-for="(item,index) in newList" :key="index">
                        
                        <a :href="item.url" target="_blank" >
                            <span class="title" v-text="item.title" :direction="item.url"></span>      
                        </a>
        
                    </li>
                    </ul>
                </vue-seamless-scroll>        
              </div>
              <div class="r-bottom"></div>
        </div>
    </div>
</template>
<script>
    export default{
         data(){
             return {
                mallList:[],
                newList:[               
                    {title:"1、冰天雪地也是金山银山｜",url:"https://www.163.com/news/article/INPH76VP000189FH.html?clickfrom=w_yw"},
                    {title:"2、诺贝尔经济学奖得主托马斯·萨金特：",url:"https://www.163.com/news/article/INPDNA5O000189FH.html?clickfrom=w_yw"},
                    {title:"3、岁末年初话信心｜消费活力涌动 潜力持续释放",url:"https://www.163.com/news/article/INPDNA5O000189FH.html?clickfrom=w_yw"},
                    {title:"4、感谢东北老铁照顾，“小砂糖橘”的老家出手了！",url:""},
                    {title:"5、AI眼中的中国｜今日小寒，望春则暖",url:""},
                    {title:"6、甘肃积石山：农牧业生产正逐步恢复",url:""},
                    {title:"7、统筹新型城镇化和乡村全面振兴",url:""},
                    {title:"8、新漫评：持续债台高筑，美国烧钱换来如此“自由”？",url:""}                   
               ]
             }
        },
        methods:{
            //异步操作
            getMallList(){
                let param ={
                        params:{
                            objType:"key-mall",
                            pageNum:1,
                            pageSize:9999
                    }                  
                }
                this.$httpapi.objList(param).then((data) =>{
                        console.log(data)
                            if(data.data.code === 200){                                
                                this.mallList = data.data.data.list;                                                                   
                            } else{
                                this.$message.error(data.data.msg)
                            }
                        })
            },
        },
        mounted(){
            this.getMallList(); 
        },
        computed:{
            defaultOption () {
                // IE浏览器会卡顿
                return {
                    step: 0.5, // 数值越大速度滚动越快
                    limitMoveNum: this.newList.length, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                    delay: 0
                }
            }
        }
    }
</script>
<style lang="less">
        .news-container {
        height: 200px;
        overflow-y: auto;
        }
     .contentppp{
         display: flex;
         justify-content: flex-start;
         width: 100%;
         height: 100%;
         .left{          
            width: 50%;
            height: 100%;
            .imageP{
                width: 100%;
                height: 100%;
                .el-carousel__container{
                      height: 100%;
                }
                .el-carousel__item 
                {
                    display:flex;
                    justify-content:center;
                    flex-direction: column;
                    align-items :center;
                    .divImage{
                        width: 100%;
                        height: calc(100% - 50px);
                        overflow: hidden;
                        .image{                                     
                            // max-width:200px;//如果图片的大小小于最大宽度和最大高度，那么它会按照原有的大小呈现；如果图片的大小大于最大宽度和最大高度，那么它会按照比例缩小，直到适应最大宽度和最大高度为止。
                            //  max-height: 150px;
                            width: auto;
                            height: 100%;
                        }
                    }                   
                    span{
                        width: 100%;
                        height: 40px;
                        padding: 20px 0px;
                        font-size: 20px;
                        color: rgb(25, 25, 24);
                    }
                }
               

                .el-carousel__item:nth-child(2n) {
                    background-color: #FFFFFF ;
                }
                
                .el-carousel__item:nth-child(2n+1) {
                    background-color: #FFFFFF ;
                }              
            }
          
         }
         .right{   
            display: flex;
            flex-direction: column;
            justify-content: space-around;  
            align-items: center;   
            width: 50%;
            height: 100%;
            .r-top{
                width: calc(100% - 40px);
                height: 50%;  

                border:2px solid #a1a1a1;
                margin-left: 10px;
                padding-top: 20px;
                padding-left: 20px;
                background:#dddddd;
                border-radius:15px;
                position: relative;

                .seamless {
                    width: 100%;
                    height: calc(100% - 20px);
                    overflow: hidden;
                    li{
                        text-align: left;//文字居左
                        height: 40px;
                        line-height: 40px;
                        a {
                           text-decoration: none; /* 移除下划线 */
                       }
                    }
                   
                }
            }
             //竖着在左边中间
             .r-top1::after {
				content: '新闻栏';
				position: absolute;
				left: -8px;
				top: calc(50% - 24px * 1.8);
				text-align: center;
				font-size: 20px;
				writing-mode: vertical-rl;
				letter-spacing: 4px;
				color: rgb(13, 13, 13);
				font-weight: 900;
				background: #dddddd;
			}
            //竖着在右边中间
            .r-top2::after {
				content: '新闻栏';
				position: absolute;
				right: -8px;
				top: calc(50% - 24px * 1.8);
				text-align: center;
				font-size: 20px;
				writing-mode: vertical-rl;
				letter-spacing: 4px;
				color: rgb(13, 13, 13);
				font-weight: 900;
				background: #dddddd;
			}
            //水平在下面靠右边
            .r-top1::after {
				content: '新闻栏';
				position: absolute;
				right: 20px;
				bottom: -7px;
				text-align: center;
				font-size: 20px;
				color: rgb(13, 13, 13);
				font-weight: 900;
				background: #dddddd;
			}
            //水平在上面靠左边
            .r-top::after {
				content: '新闻栏';
				position: absolute;
				left: 20px;
				top: -10px;
				text-align: center;
				font-size: 16px;
				color: rgb(13, 13, 13);
				font-weight: 900;
				background: #dddddd;
			}
            .r-bottom{
                width: 100%;
                height: 50%;
            }
         }
     }
</style>
export default class test5{

     getMax(a,b){  //如果不加static,则需要调用时自己new一下，否则报错 因为这个类没有实例化
        if(a<b){
            return b;
        }else{
            return a;
        }
    }

}
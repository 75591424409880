import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import test from './test'

Vue.use(Vuex)

export default  new Vuex.Store({
    modules:{
        test,
        tab,
       
    }
})
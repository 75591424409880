<template>
        <div class="footer">用户: {{userName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 当前时间：{{ currentTime }} </div>
</template>
<script>
     export default{
        data(){
           return{
            currentTime: '',
            userName:window.localStorage.getItem('userName')
           }; 
        },
        methods:{
          startDate() {
              this.timer = setInterval(() => {
                this.updateTime()
                }, 1000);
            },
            updateTime() {
                const now = new Date();
                this.currentTime = now.getFullYear() + "年" +
                (now.getMonth() + 1) + "月" +
                now.getDate() + "日 " +
                now.toLocaleTimeString();
             }

        },
        mounted() {
            this.updateTime();
            this.startDate();
       },
       beforeDestroy() {
          // 在组件销毁前，清除定时器以避免内存泄漏
           clearInterval(this.timer);
       }
     }
</script>
<style lang="less">
  .footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre;
    //font-weight: bold;
  }

  .el-footer{
    display: flex;
    justify-content: center;
    align-items: center; 
  }

</style>
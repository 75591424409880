export default class myUtils{

     /**
     * 等待时间
     * @param  毫秒
     */
    static wait(time) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, time)
        })
      }

    static getMax(a,b){  //如果不加static,则报错 因为这个类没有实例化
        if(a<b){
            return b;
        }else{
            return a;
        }
    }
    /**
     * 判断字符串是否为空
     * @param str
     * @returns {boolean}
     */
    static isNull(str) {
        return str == null || str.length === 0 || str === '';
    }

    static add(a,b){
        if(a<0){
            throw new Error("param is error")
        }
        return a + b;
    }

}
import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import httpapi from './api/httpapi.js'
import store from './store'
import moment from 'moment';
import scroll from "vue-seamless-scroll";
import {message} from './utils/message';
import myUtils from './utils/myUtils' 

//全局时间格式化
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
  }
});
//全局时间格式化
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
});

Vue.use(scroll);
Vue.use(ElementUI);//组件全局注入到vue

//设置全局自定义配置
Vue.prototype.$domain = 'https://wandao666.top/'; //后端域名


Vue.config.productionTip = false
Vue.prototype.$httpapi = httpapi
Vue.prototype.$message = message
Vue.prototype.$myUtils = myUtils
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')




<template>
    <div>
        <el-row>
            <el-col :lg="6" :xs="12"  v-for="(item, index) in goods" :key="index" >
                <el-card :body-style="{ padding: '0px' }">                 
                     <div class="divImage" @click="imageClick(item)">
                         <img :src="domain+item.imageMain" class="image">
                    </div>
                <div  class="more_info">
                  <el-tooltip class="item" effect="light" :content="item.remark" placement="bottom"> <!--鼠标上去时hover全部的说明-->
                     <span class="twolines">{{item.remark}}</span>
                  </el-tooltip>
                    <div class="mod_price">
                      <i>¥</i>
                      <div class="more2_info_price_txt">{{ item.priceNew }}</div>
                    </div>
                </div>
                </el-card>
            </el-col>
       </el-row>
    </div>
       
</template>
<script>
     export default{
       props: ['goods'],
       data(){
           return {
             domain: this.$domain,
           }    
        },
        methods:{
          imageClick(item)
          {
              var str = JSON.stringify(item);
              // console.log(str);
              window.sessionStorage.mallInfo = str; 
              this.$router.push('/malldetail') 
          }
        },
        mounted(){
           console.log(window.localStorage.getItem('domain'))
        }
     }
</script>
<style lang="less" scoped>

.divImage{
  width: 250px;
  height: 300px;
  overflow: hidden;
  .image{
   // max-width:250px;
   // max-height: 300px;
    width: auto;
    height: 100%;
  }
}
:deep(.el-card__body){
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //background-color: aqua;
}
.more_info{
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
  height: calc(100% - 24px);
  .twolines {
          text-align: left;       
          overflow: hidden; // 溢出的内容隐藏
          text-overflow: ellipsis; // 溢出的部分用省略号...显示
          -webkit-line-clamp: 2; // 行数
          line-clamp: 2;
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示 
          -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
           min-height: 40px; // 确保即使只有一行数据，元素也会显示两行的高度
  }
  .mod_price{
    padding-top: 10px;
     display: flex;
    flex-direction:row;
    justify-content:flex-start;
    font-size: 20px;
    font-weight:600;
    color: #FF0000; 
    .more2_info_price_txt{
      padding-left: 10px;
    }
  }
}
 
</style>
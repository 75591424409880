class VoiceAnnouncements {  
    constructor() {  
        this.synth = window.speechSynthesis; // 启用文本  
        this.msg = new SpeechSynthesisUtterance();  
        this.language = 'zh-CN';  // 使用的语言:中文  
        this.volume = 1; // 音量  
        this.speed = 1;  // 语速  
        this.pitch = 1; // 音高  
    }  

    // 开始语音提示  
    startVoiceFunction(content) {  
        this.msg.text = content;  
        this.msg.language = this.language;  
        this.msg.volume = this.volume;  
        this.msg.rate = this.speed; // 将 `speed` 调整为 `rate`  
        this.msg.pitch = this.pitch;  
        this.synth.speak(this.msg);  
    }  

    // 停止语音提示  
    stopVoiceFunction(content) {  
        this.msg.text = content;  
        this.msg.language = this.language;  
        this.synth.cancel(this.msg);  
    }  
}  

// 传出实例，保证整个系统只存在单例的Voice  
const VoiceAnnouncementsInstance = new VoiceAnnouncements();  

export default VoiceAnnouncementsInstance;
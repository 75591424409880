//多个导出
var arr = ['a', 'b', 'c', 'd']       //导出数组
var stringName = 'abc'        //导出字符串
var obj = { name: 'xq', age: '18', id: 1 }  //导出对象
function way() {   //导出方法
    return "调用"+way1();
}

function way1() {   //没有导出该方法，不能在外部调
    return '方法1'
}
export default {stringName}    //export default abc  这样导出是错误的
<template>
  <el-transfer class="custom-transfer" v-model="value" :data="data"></el-transfer>
</template>

<script>
  export default {
    data() {
      const generateData = _ => {
        const data = [];
        for (let i = 1; i <= 15; i++) {
          data.push({
            key: i,
            label: `备选项 ${ i }`,
            disabled: false
          });
        }
        return data;
      };
      return {
        data: generateData(),
        value: [1, 4]
      };
    }
  };
</script>

<style lang="less" scoped>


:deep(.el-checkbox){
  background-color: blueviolet;
}
</style>
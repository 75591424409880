import Vue from "vue";
import VueRouter from "vue-router";

import Main from '../views/Main.vue'
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Login from '../views/login.vue'
import Mall from '../views/Mall.vue'
import PageOne from '../views/PageOne.vue'
import PageTwo from '../views/PageTwo.vue'
import Page3 from '../views/Page3.vue'
import Page4 from '../views/Page4.vue'
import Page5 from '../views/Page5.vue'
import MallList from '../views/MallList.vue'
import MallDetail from "../views/MallDetail.vue";
import Logging from "@/views/Logging.vue";
import SystemConfig from "@/views/SystemConfig.vue";
import Mornitor from '../views/Monitor.vue';
import RoleMng from "@/views/RoleMng.vue";

Vue.use(VueRouter)

//2 将路由与组件进行映射
const routes = [
    {
        path: '/',
        component: Main,
        redirect:'/login',
        children:[
            { path: '/home',name:'home', component: Home },//首页
            { path: '/user', name:'user', component: User },//用户管理
            { path: '/mall', name:'mall', component: Mall },//商品管理
            { path: '/malllist', name:'malllist', component: MallList },//商品展示
            { path: '/page1',name:'page1',  component: PageOne },//页面1
            { path: '/page2',name:'page2',  component: PageTwo },//页面2
            { path: '/page3',name:'page3',  component: Page3 },//页面2
            { path: '/page4',name:'page4',  component: Page4 },//页面2
            { path: '/page5',name:'page5',  component: Page5 },//页面2
            { path: '/malldetail',name:'malldetail',  component: MallDetail },//页面2
            { path: '/logging',name:'logging',  component: Logging },//日志监控
            { path: '/monitor',name:'monitor',  component: Mornitor },//实时监控
            { path: '/rolemng',name:'rolemng',  component: RoleMng },//角色管理
            { path: '/systemconfig',name:'systemconfig',  component: SystemConfig }//系统设置
        ]
    },
    {
        path: '/login',
        component: Login,  
    }
   
]
//3 创建router实例
const router = new VueRouter({
    routes //缩写 相当于 routes:routes
})


//防止目标路径与当前路径相同的情况发生。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  if (location !== this.currentRoute.fullPath) {
    return originalPush.call(this, location).catch(err => err)
  } else {
    return Promise.resolve()
  }
}


export default router
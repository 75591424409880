<template>
   
    <div class="content">
        <p ref="logContainer" contenteditable="true"  class="logging" v-html="rendelogHtml"></p>
        <div class="logButton">
            <el-button type="success" plain @click="clearlog">一键清空</el-button>
            <el-button type="success" plain @click="goBottom">滚动到底</el-button>
            <el-button type="success" plain @click="loggingAutoBottom">{{ autoBottomText }}</el-button>
       </div>
     </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        logs: [],
        autoBottom:true,
        autoBottomText:'关闭自动滚动'
      };
    },
    created() {
      this.setupWebSocket();
    },
    computed:{
        rendelogHtml(){
            if (this.logs.length === 0) {
                return "";
            } else {
                return this.logs;
            }
        }
    },
    methods: {
      
      setupWebSocket() {

        const ws = new WebSocket('wss://wandao666.top/websocket/logging/' + window.localStorage.getItem('token')); // 替换成你的WebSocket服务器地址
  
        ws.onopen = () => {
          console.log('WebSocket connected');
        };
  
        ws.onmessage = (event) => {
            if (event.data) {
                this.logs.push(event.data);
                if(this.autoBottom)
                {
                    this.$nextTick(() => {//这里必须这么写 否则会报scrollHeight未定义
                        const container = this.$refs.logContainer;
                        container.scrollTop = container.scrollHeight;
                    });
                }
            }         
        };
  
        ws.onclose = () => {
          console.log('WebSocket closed');
        };
  
        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
      },
      clearlog(){
        this.logs = [];
      },
      goBottom(){
        const container = this.$refs.logContainer;
        container.scrollTop = container.scrollHeight;
      },
      loggingAutoBottom(){     
        this.autoBottom = !this.autoBottom;
        if(this.autoBottom)
        {
            this.autoBottomText = '关闭自动滚动'
        }else{
            this.autoBottomText = '开启自动滚动'
        }
       
      }
     
    }
  };
  </script>
<style lang="less">
 //style="width:100%;height: 500px;background-color: ghostwhite; overflow: auto;
   .content{
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     width:100%;
     height: 100%;
     background-color:ghostwhite;
    
     .logging{
        width:100%;
        height: 450px; 
        overflow:auto;
        text-align: left;
     }
     .logButton{
        width:100%;
        height: 40px;  
        margin-bottom: 10px;
     }
   }
</style>
<template>
   <div>
      <h1> 我是PageTwo: {{ a }}   ,min: {{ b }}  ,max: {{ c }}  ,name: {{ d }} ,name2: {{e }} ,way：{{ f }} </h1>
      <p> stringName:{{ g }} , test5Max:{{ h }}</p>
      <button @click="startSpeaking">播放语音</button>
   </div>
  

</template>
<script>
import myUtils from '@/utils/myUtils';// 等同于'../utils/myUtils';
import test from '../utils/test'
import { obj} from '../utils/test2'  //这里或者写成 import * as p ， 不能写成 import test2 from '../utils/test2',因为没有用export defaute
import data from '../utils/test3' 

import abc from '../utils/test4'
import Test5 from '../utils/test5'
import VoiceAnnouncementsInstance from '@/utils/VoiceAnnouncements';  


export default{
   data() {
       return {
          a:'',
          b:'',
          c:'',
          d:'',
          e:'',
          f:'',
          g:'',
          h:''
       }
   },
   methods:{
       startSpeaking() {  
         console.log("Start speaking...");  
         VoiceAnnouncementsInstance.startVoiceFunction('我是一个兵');  
         }  
   },
   mounted(){
      let test5  = new Test5;
      this.h = test5.getMax(100,200);

      this.a = myUtils.add(1,6);
      this.b = test.getMin(2,7);
      this.c = myUtils.getMax(2,7);
      this.d = obj.name;
      this.e = data.obj.name
      this.f = data.way()
      this.g = abc.stringName
   }
 }
</script>
export default{
    state:{
        tabList:[//面包屑
         {
            path: '/home',
            name: 'home',
            label: '首页',
            icon: 's-home',
            url: 'Home.vue'
          }
        ],
    },
    mutations:{
        //更新面包屑数据
        addTag(state,val){
           //console.log(val)
           //判断添加的数据是否为首页
           if(val.name !== 'home'){
              const index = state.tabList.findIndex(item => item.name === val.name);
              if(index < 0)
              {
                  state.tabList.push(val)
              }
           }
        },
        //删除指定的tag数据
        closeTag(state,item){
           console.log(item)
           const index = state.tabList.findIndex(val => val.name === item.name)
           state.tabList.splice(index,1)
        },
      
    }
}
<template>
    <div class="manage">   

        <!--新建对话框-->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogAddVisible"
            width="40%"
            :before-close="handleClose">

            <el-form ref="form" :rules="rules"  :model="form" label-width="80px">
                <el-form-item label="活动名称"  prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="商品类型"  prop="type" >
                    <el-select v-model="form.type" placeholder="请选择商品类型">
                        <el-option label="水果蔬菜" value="1"></el-option>
                        <el-option label="肉禽海产" value="2"></el-option>
                        <el-option label="粮油调味" value="3"></el-option>
                        <el-option label="休闲零食" value="4"></el-option>
                        <el-option label="电子产品" value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位"  prop="unit">
                    <el-input v-model="form.unit"></el-input>
                </el-form-item>
                <el-form-item label="原本价格"  prop="priceOld">
                    <el-input v-model="form.priceOld"></el-input>
                </el-form-item>
                <el-form-item label="活动价格"  prop="priceNew">
                    <el-input v-model="form.priceNew"></el-input>
                </el-form-item>
              
                <!--想让多个el-form-item在一行，就用el-row和el-col-->
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="生产日期"  prop="productionDate">                  
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.productionDate" style="width: 100%;"></el-date-picker>                   
                        </el-form-item>
                   </el-col>
                   <el-col :span="6">
                        <el-form-item label="京东配送"  prop="natureJD">
                            <el-switch v-model="form.natureJD"></el-switch>
                        </el-form-item>
                  </el-col>
               </el-row>

                <el-form-item label="参加活动"  prop="eventType">
                    <el-checkbox-group v-model="form.eventType">
                    <el-checkbox label="1" name="type">线上活动</el-checkbox>
                    <el-checkbox label="2" name="type">地推活动</el-checkbox>
                    <el-checkbox label="3" name="type">主题活动</el-checkbox>
                    <el-checkbox label="4" name="type">品牌曝光</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="颜色"  prop="color">
                    <el-radio-group v-model="form.color">
                    <el-radio label="1">红色</el-radio>
                    <el-radio label="2">绿色</el-radio>
                    <el-radio label="3">白色</el-radio>
                    <el-radio label="4">黑色</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="图片"  prop="imageMain">
                    <el-upload
                            class="avatar-uploader"
                            action="https://wandao666.top/api/file/upload"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="form.imageMain" :src="'https://wandao666.top/'+ form.imageMain" class="image">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                     </el-upload>
                </el-form-item>
                <el-form-item label="备注"  prop="remark">
                    <el-input type="textarea" v-model="form.remark"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">确定</el-button>
                    <el-button type="primary" @click="cancel">取消</el-button>
                </el-form-item>
                </el-form>
        </el-dialog>

        <!--正式表格部分-->
       <div class="menage-header">
        <el-button @click="handle_add" type="primary">
               新增
        </el-button>
         <!--搜索区域--> 
         <el-form class="query" :inline="true" :model="userSeachForm">
            <el-form-item>
                    <el-input placeholder="请输入姓名" v-model="userSeachForm.name"></el-input>
            </el-form-item>
            <el-form-item>
                  <el-button type="primary" @click="querysubmit">查询</el-button>
            </el-form-item>
         </el-form>
       </div>
       
       <div class="common-table">
         <el-table v-loading="loading" stripe height="90%" :data="tableData" border style="width: 100%;" :header-cell-style="{'text-align':'center'}">
                 <el-table-column prop="name" label="商品名称"> </el-table-column>
                 <el-table-column prop="type" label="类型">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ getTypeStr(scope.row.type) }}</span>
                    </template>    
                </el-table-column>
                 <el-table-column prop="unit" label="单位"> </el-table-column>
                 <el-table-column prop="priceOld" label="原来价格"> </el-table-column>
                 <el-table-column prop="priceNew" label="优惠价格"> </el-table-column> 
                 <el-table-column prop="productionDate" label="生产日期" width=120px> 
                    <template slot-scope="scope" >
                        <span style="margin-left: 10px">{{ scope.row.productionDate | formatDate  }}</span>
                    </template> 
                 </el-table-column>
                 <el-table-column prop="eventType" label="活动类型"  width=100px show-overflow-tooltip> 
                    <template slot-scope="scope" >
                        <span style="margin-left: 10px">{{ getEventTypeStr(scope.row.eventType) }}</span>
                    </template> 
                 </el-table-column> 
                 <el-table-column prop="color" label="颜色"> 
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ getColorStr(scope.row.color) }}</span>
                    </template> 
                 </el-table-column> 
                 <el-table-column prop="natureJD" label="京东配送">
                    <template slot-scope="scope">
                      <span style="margin-left: 10px">{{ scope.row.natureJD==1?'是':'否' }}</span>
                    </template>
                  </el-table-column>
                 <!--  shape 图片显示方式 circle为原型像 square为方形 -->
                 <el-table-column prop="imageMain" label="主图片" width="90" align="center">
                <template slot-scope="scope">
                    <el-avatar shape="circle" :size="50" :src="'https://wandao666.top/'+scope.row.imageMain" @click.native="showImage(scope.row.imageMain)"></el-avatar>
                    　<!--<img class="avatar" :src="scope.row.avatar" @click="showImage(scope.row.avatar)" />-->
                </template>
                </el-table-column>
                 
                 <el-table-column prop="createDate" label="创建时间" show-overflow-tooltip> </el-table-column>
                 <el-table-column prop="remark" label="描述" show-overflow-tooltip> </el-table-column>
                 <el-table-column prop="addr" label="操作" width=150px >
                    <!--自定义列 修改 删除-->
                   <template slot-scope="scope">
                         <el-button size="mini" @click="handle_edit(scope.row)" >编辑</el-button>
                         <el-button type="danger" size="mini" @click="handle_del(scope.row)">删除</el-button>
                    </template>
                  </el-table-column>
         </el-table>
          <div class="pager">
            <el-pagination
               layout="prev, pager, next"
               :total="tableTotal"
               :current-page="pageData.pageNum"
               :page-size="pageData.pageSize"
               @current-change="handle_Page"> <!--事件用@ 属性用冒号：-->
             </el-pagination>
          </div> 
       </div>  
  
       <el-dialog :visible.sync="dialogVisible" width="34%">
            <img :src="currentImage" style="width: 100%" />
       </el-dialog>

     </div>
</template>
<script>
import Vue from 'vue'
   export default{
        data(){
           return {
                loading: true,
                dialogTitle:'新建商品',
                form: {
                    objType:"key-mall",
                    name: '',
                    type: '',
                    unit: '',
                    priceOld: '',
                    priceNew: '',
                    productionDate: '',
                    eventType: [],
                    natureJD: false,
                    imageMain: '',
                    color:'',
                    createDate: '',
                    remark: ''
              },
            rules:{
                name:[
                {  required:true, message:'请输入商品名称' },
                {  min:2,max:50,message:'长度大于1 小于50' }
                ],
                type:[
                    {  required:true, message:'请选择商品类型' }
                ],
                unit:[
                    { required:true, message:'请输入单位' }
                ],
                imageMain:[
                    {  required:true, message:'请选择图片'}
                ],
                priceNew:[
                    { required:true, message:'请输入单价'  }
                ]

               },
               modelType:0, //0表示新增 1表示编辑
               dialogAddVisible:false,
               dialogVisible:false,
               currentImage: '',
               tableData:[],
               userSeachForm:{
                   name:''
               },
               tableTotal:0, //当前的总条数
               pageData:{
                   pageNum:1,
                   pageSize:4
               }, 
               //上传图片
               imageUrl: '',
               headers:{
                token: window.localStorage.getItem('token'),
                serialNo:'Mng-' +  new Date().getTime()
               }
           }
       },
       methods:{
        getColorStr(key)
        {
            var myMap = new Map();
            myMap.set("1","红色");
            myMap.set("2","绿色");
            myMap.set("3","白色");
            myMap.set("4","黑色");
            return myMap.get(key);
        },
        getTypeStr(key)
        {
            var myMap = new Map();
            myMap.set("1","水果蔬菜");
            myMap.set("2","肉禽海产");
            myMap.set("3","粮油调味");
            myMap.set("4","休闲零食");
            myMap.set("5","电子产品");
            return myMap.get(key);
        },
        getEventTypeStr(key)
        {
            let str ="";
            var myMap = new Map();
            myMap.set("1","线上活动");
            myMap.set("2","地推活动");
            myMap.set("3","主题活动");
            myMap.set("4","品牌曝光");
            for (let i = 0; i < key.length; i++) {
                if(i==0)
                {
                    str =  myMap.get(key[i]);
                }else{
                    str = str + "," + myMap.get(key[i])
                }
           }
           console.log(str);
            return str;
        },
        cancel(){
            this.handleClose();
            this.$refs.form.resetFields();
        },
        handle_add(){
            this.modelType = 0;
            this.dialogTitle='新建商品';
            this.dialogAddVisible = true;
        },
        handle_del(row){
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                console.info(row)
            let param ={
                params:{
                    objType:"key-mall",
                    id:row.id,
                }                  
            }    
            this.$httpapi.objDel(param).then(() =>{
                    this.getList();   
                    this.$message({
                    type: 'success',
                    message: '商品删除成功!'
                    });
                })

            
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
            });
       },
        handle_edit(row){
            this.modelType = 1;
            this.dialogTitle='修改商品';
            this.dialogAddVisible = true;
            //注意这里需要对当前行数据惊醒深拷贝，否则会出错
            this.form = JSON.parse(JSON.stringify(row))
       },
        handleClose(){
            this.$refs.form.resetFields();
            this.dialogAddVisible = false;
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
            if(valid){//验证通过            
                if(this.modelType == 0)
                {
                    this.$httpapi.objAdd(this.form).then((data) =>{
                        console.log(data)
                        if(data.data.code === 200){                                
                            this.dialogAddVisible = false;     
                            this.getList();     
                            this.$message.info("商品创建成功.")                                                      
                        } else{
                            this.$message.error(data.data.msg)
                        }
                        this.$refs.form.resetFields();
                })
                }else{
                    this.$httpapi.objUpdate(this.form).then((data) =>{
                        console.log(data)
                        if(data.data.code === 200){                                
                            this.dialogAddVisible = false;    
                            this.getList();      
                            this.$message.info("商品修改成功.")                                           
                        } else{
                            this.$message.error(data.data.msg)
                        }
                        this.$refs.form.resetFields();
                })
                }
            }
        })
        },
         showImage(imageUrl){
             this.currentImage = 'https://wandao666.top/'+ imageUrl;
             this.dialogVisible = true;
         },
          //异步操作
           getList()
           {
               let param ={
                    params:{
                        objType:"key-mall",
                        pageNum:this.pageData.pageNum,
                        pageSize:this.pageData.pageSize
                   }                  
               }
               this.$httpapi.objList(param).then((data) =>{
                       console.log(data)
                           if(data.data.code === 200){                                
                             this.tableData = data.data.data.list; 
                             this.tableTotal = data.data.data.total;    
                             console.info(this.tableTotal )                                                
                           } else{
                               this.$message.error(data.data.msg)
                           }
                           this.$myUtils.wait(500).then(() => {
                               this.loading = false;
                           })
                      })
           },

            //列表查询
           querysubmit(){
               this.getList()
           },
           //选择页码的回调函数
           handle_Page(val){
               this.pageData.pageNum = val
               this.getList();
           },
           //上传图片          
           handleAvatarSuccess(res) {
            console.log(res);
                this.form.imageMain = res.data.filePath.substring(res.data.filePath.indexOf("/upload"));
                console.log(this.imageUrl);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
            
  

       },
       mounted(){
           this.getList();
       }
   }
</script>
<style lang="less" scoped>
.manage{
        height: 100%;
        .menage-header{
           height: 40px;
           margin-bottom: 10px;
           display: flex;
           justify-content: space-between;
           align-items: center;
           .query{
              margin-top: 0px;
           }
        }
        .common-table
        {
            position: relative;
            height: calc(100% - 40px);
            .pager{
             position: absolute;
             bottom: 0;
             right: 20px;
            }
            
            .avatar{//现在不用
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
       
    }
    :deep( .el-table .cell) {      
        height: 50px;  
        line-height: 50px;
    }

    :deep(.query  .el-form-item ){
        margin-bottom: 0px
    }
    :deep(.el-select){ //解决新建form中el-select宽度不够且靠右的问题
        width: 100%;
    }
    :deep(.el-dialog .el-dialog__body){
        padding: 10px,10px;
    }
    .image{//现在不用
                width: 300px;
                height: 300px; 
                border: 1px solid;           
            }
</style>
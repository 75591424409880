class test{

    getMin(a,b){  //这里不用加static 因为下面已经实例化了
        if(a<b){
            return a;
        }else{
            return b;
        }
    }
}

export default new test(); //用的是export default方法，并且导出的时候就已经实例化了，
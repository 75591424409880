<template>
  <div>
      我是Mall
      <p>{{hellocount}}</p> 
      <p>{{count}}</p> 
      <p>{{count2}}</p>
      <p>{{name}}</p>
      <p>{{age}}</p>
      <p>{{sex}}</p>
      <el-button type="success" plain @click="addCount">新增</el-button>
      <el-button type="success" plain @click="minusCount">减去</el-button>
  </div>
</template>
<script>
import { mapState,mapMutations } from 'vuex';
  export default{
       data(){
          return {
              
          }
      },
      methods:{
         addCount(){
           this.$store.commit('addCount', 2)
         },
         minusCount(){
           this.$store.commit('minusCount', 2)
         }
      },
      computed:{
          count() { // 这实际上是ES6中对象的简化写法 完整写法是 count: function { return this.$store.state.count }
             return this.$store.state.test.count
          },
          name() {
             return this.$store.state.test.name
          },
          
         ...mapState({
              age: (state) => { return state.test.age  },// 映射 tags 为 store.tab.age                                 
              sex:state => state.test.sex,
              count2:state => state.test.count + 1000,
              hellocount:function(state){
                  return "hello count is " +  state.test.count 
              }   
         })
      }
  }
</script>
<style lang="less">
</style>
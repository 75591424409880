<template>
    <div>  
        <el-container>
            <el-aside  width="auto">
               <CommonAside/>
            </el-aside>
            <el-container>
                <el-header>  
                      <CommonHeader/> 
                </el-header>
                      <CommonTag/>
                    <el-main>
                        <router-view></router-view>
                    </el-main>
                <el-footer>
                    <CommonFooter/>
                </el-footer>
            </el-container>
        </el-container>
    </div>
    
</template>
<script>
    import CommonAside from '../components/CommonAside.vue'
    import CommonFooter from '../components/CommonFooter.vue'
    import CommonHeader from '../components/CommonHeader.vue'
    import CommonTag from '../components/CommonTag.vue'

    export default{
         data(){
           return {}
        },
        components:{
            CommonAside,
            CommonFooter,
            CommonHeader,
            CommonTag
        }
    }
</script>
<style lang="less" scoped>
  
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    height: 60px;
    padding: 0;
  }
  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    min-height: 45px;  //默认footer高度是60，必须用最大最小来重新设置高度，光用height无效
    max-height: 45px;
  }
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 40px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 20px;
    padding: 10px;
  }
  

  
  .el-main{
       height: calc(100vh - 165px); //保证右边是一整屏，除去上面的header60和footer45、 tag 40 高度就是主界面的高度
   }

</style>